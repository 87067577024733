import { useCallback, useState } from 'react';
import { useAccount } from 'wagmi';
import { Address } from 'viem';
import { publicClient } from '../config/viem';
import { BAB_ABI, BAB_CONTRACT_ADDRESS } from '../config/viem';

export const useBABBalance = () => {
  const { address } = useAccount();
  const [hasBAB, setHasBAB] = useState(false);

  const checkBABBalance = useCallback(async () => {
    if (!address) return;

    try {
      const balance = await publicClient.readContract({
        address: BAB_CONTRACT_ADDRESS as Address,
        abi: BAB_ABI,
        functionName: 'balanceOf',
        args: [address],
      });

      setHasBAB(balance > 0n);
    } catch (error) {
      console.error('Failed to check BAB balance:', error);
      setHasBAB(false);
    }
  }, [address]);

  return {
    hasBAB,
    checkBABBalance,
  };
};
