import React from 'react';
import { Card, CardBody, VStack, Heading, Spinner, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RoundData } from '../types';
import { format } from 'date-fns';

interface HistoricalRoundsCardProps {
  isLoading: boolean;
  historicalRounds: RoundData[];
}

export const HistoricalRoundsCard: React.FC<HistoricalRoundsCardProps> = ({ isLoading, historicalRounds }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} align="stretch">
          <Heading size="md">{t('history.title')}</Heading>

          {isLoading ? (
            <VStack spacing={4} align="center" py={4}>
              <Spinner />
              <Text>{t('history.loading')}</Text>
            </VStack>
          ) : historicalRounds.length > 0 ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t('time')}</Th>
                  <Th isNumeric>{t('round.participants')}</Th>
                  <Th isNumeric>{t('history.claimed')}</Th>
                  <Th isNumeric>{t('round.rewardPerson')} (BABD)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {historicalRounds.map((round) => (
                  <Tr key={round.timestamp}>
                    <Td>{format(round.timestamp * 1000, 'yyyy-MM-dd HH:mm')}</Td>
                    <Td isNumeric>{round.userCount}</Td>
                    <Td isNumeric>{round.claimedCount}</Td>
                    <Td isNumeric>{round.rewardPerParticipant}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text color="gray.500" textAlign="center" py={4}>
              {t('history.noHistory')}
            </Text>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
