import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Address, formatEther } from 'viem';
import { publicClient } from '../config/viem';
import { BABD_ABI, BABD_CONTRACT_ADDRESS } from '../config/viem';
import { RoundData } from '../types';

export const useHistoricalRounds = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [historicalRounds, setHistoricalRounds] = useState<RoundData[]>([]);
  const toast = useToast();

  const loadHistoricalRounds = useCallback(
    async (nextRoundTime: number) => {
      console.log('Loading historical rounds with nextRoundTime:', nextRoundTime);
      try {
        setIsLoading(true);

        // 获取轮次周期
        const roundPeriodResult = await publicClient.readContract({
          address: BABD_CONTRACT_ADDRESS as Address,
          abi: BABD_ABI,
          functionName: 'ROUND_PERIOD',
        });

        const currentTime = Math.floor(Date.now() / 1000);
        const nextRoundTimeBigInt = BigInt(nextRoundTime);
        const roundPeriod = roundPeriodResult;

        // 准备multicall调用
        const calls = [];
        for (let i = 1; i <= 10; i++) {
          const roundTime = nextRoundTimeBigInt - roundPeriod * BigInt(i);

          // 如果轮次时间超过30天，跳过
          if (Number(roundTime) < currentTime - 30 * 24 * 60 * 60) {
            break;
          }

          calls.push({
            address: BABD_CONTRACT_ADDRESS as Address,
            abi: BABD_ABI,
            functionName: 'rounds',
            args: [`0x${roundTime.toString(16)}` as `0x${string}`],
          });
        }

        // 使用multicall获取所有轮次数据
        const results = await publicClient.multicall({
          contracts: calls,
        });

        // 处理结果
        const rounds: RoundData[] = [];
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          if (result.status === 'success' && Array.isArray(result.result) && result.result.length === 3) {
            const roundTime = nextRoundTimeBigInt - roundPeriod * BigInt(i + 1);
            const [rewardPerParticipant, userCount, claimedCount] = result.result;

            if (typeof rewardPerParticipant === 'bigint' && typeof userCount === 'bigint' && typeof claimedCount === 'bigint') {
              rounds.push({
                timestamp: Number(roundTime),
                rewardPerParticipant: formatEther(rewardPerParticipant),
                userCount: Number(userCount),
                claimedCount: Number(claimedCount),
              });
            }
          }
        }

        console.log('Setting historical rounds:', rounds);
        setHistoricalRounds(rounds);
      } catch (error) {
        console.error('Failed to load historical rounds:', error);
        toast({
          title: 'Error',
          description: 'Failed to load historical rounds',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [toast],
  );

  return {
    isLoading,
    historicalRounds,
    loadHistoricalRounds,
  };
};
