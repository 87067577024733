import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { formatEther, Address } from 'viem';
import { publicClient } from '../config/viem';
import { BABD_ABI, BABD_CONTRACT_ADDRESS } from '../config/viem';
import { RoundData } from '../types';

export const useRoundData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentRound, setCurrentRound] = useState<RoundData | null>(null);
  const [lastParticipation, setLastParticipation] = useState(0);
  const [hasParticipatedInCurrentRound, setHasParticipatedInCurrentRound] = useState(false);
  const [nextRoundTime, setNextRoundTime] = useState(0);
  const toast = useToast();

  const loadRoundData = async (address?: Address) => {
    try {
      setIsLoading(true);

      // 使用multicall并行获取数据
      console.log('Fetching contract data...');
      const [nextRoundTimeResult, roundPeriodResult, contractBalanceResult] = await publicClient.multicall({
        contracts: [
          {
            address: BABD_CONTRACT_ADDRESS as `0x${string}`,
            abi: BABD_ABI,
            functionName: 'nextRoundTime',
          },
          {
            address: BABD_CONTRACT_ADDRESS as `0x${string}`,
            abi: BABD_ABI,
            functionName: 'ROUND_PERIOD',
          },
          {
            address: BABD_CONTRACT_ADDRESS as `0x${string}`,
            abi: BABD_ABI,
            functionName: 'balanceOf',
            args: [BABD_CONTRACT_ADDRESS as `0x${string}`],
          },
        ],
      });

      console.log('Contract data results:', { nextRoundTimeResult, roundPeriodResult, contractBalanceResult });

      if (!nextRoundTimeResult.status || !roundPeriodResult.status || !contractBalanceResult.status) {
        throw new Error('Failed to fetch contract data: Invalid status');
      }

      const nextRoundTimeValue = nextRoundTimeResult.result;
      const roundPeriod = roundPeriodResult.result;
      const contractBalance = contractBalanceResult.result;

      if (typeof nextRoundTimeValue !== 'bigint' || typeof roundPeriod !== 'bigint' || typeof contractBalance !== 'bigint') {
        throw new Error('Invalid data type returned from contract');
      }

      // 计算上一轮次时间
      const previousRoundTime = nextRoundTimeValue - roundPeriod;

      console.log('Fetching round data...');
      // 获取当前轮次和上一轮次数据
      const [currentRoundResult, previousRoundResult, userLastParticipationResult] = await publicClient.multicall({
        contracts: [
          {
            address: BABD_CONTRACT_ADDRESS as `0x${string}`,
            abi: BABD_ABI,
            functionName: 'rounds',
            args: [`0x${nextRoundTimeValue.toString(16)}`],
          },
          {
            address: BABD_CONTRACT_ADDRESS as `0x${string}`,
            abi: BABD_ABI,
            functionName: 'rounds',
            args: [`0x${previousRoundTime.toString(16)}`],
          },
          ...(address
            ? [
                {
                  address: BABD_CONTRACT_ADDRESS as `0x${string}`,
                  abi: BABD_ABI,
                  functionName: 'userLastParticipationTime',
                  args: [address],
                },
              ]
            : []),
        ],
      });

      console.log('Round data results:', { currentRoundResult, previousRoundResult, userLastParticipationResult });

      if (!currentRoundResult.status || !previousRoundResult.status) {
        throw new Error('Failed to fetch round data: Invalid status');
      }

      const currentRoundData = currentRoundResult.result;
      const previousRoundData = previousRoundResult.result;

      if (
        !Array.isArray(currentRoundData) ||
        !Array.isArray(previousRoundData) ||
        currentRoundData.length !== 3 ||
        previousRoundData.length !== 3 ||
        typeof currentRoundData[0] !== 'bigint' ||
        typeof currentRoundData[1] !== 'bigint' ||
        typeof currentRoundData[2] !== 'bigint' ||
        typeof previousRoundData[0] !== 'bigint' ||
        typeof previousRoundData[1] !== 'bigint' ||
        typeof previousRoundData[2] !== 'bigint'
      ) {
        throw new Error('Invalid round data format');
      }

      // 计算上一轮次未领取的总金额
      const unclaimedCount = previousRoundData[1] - previousRoundData[2];
      const unclaimedAmount = unclaimedCount * previousRoundData[0];

      // 计算当前轮次的每人奖励
      // 计算预估值
      const availableBalance = contractBalance - unclaimedAmount;
      // 检查是否超过最大奖励限制 (10000 tokens)
      const maxReward = 10000n * 10n ** 18n;
      const rewardPerParticipant = availableBalance > maxReward ? maxReward : availableBalance;

      setCurrentRound({
        timestamp: Number(nextRoundTimeValue),
        rewardPerParticipant: formatEther(rewardPerParticipant),
        userCount: Number(currentRoundData[1]),
        claimedCount: Number(currentRoundData[2]),
      });

      setNextRoundTime(Number(nextRoundTimeValue));

      if (address && userLastParticipationResult?.status) {
        const lastParticipationTime = userLastParticipationResult.result;
        console.log('Last participation time:', lastParticipationTime, 'Next round time:', nextRoundTimeValue);
        if (typeof lastParticipationTime === 'bigint') {
          setLastParticipation(Number(lastParticipationTime));
          setHasParticipatedInCurrentRound(lastParticipationTime === nextRoundTimeValue);
        }
      }
    } catch (error) {
      console.error('Failed to load round data:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to load round data',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    currentRound,
    nextRoundTime,
    lastParticipation,
    hasParticipatedInCurrentRound,
    loadRoundData,
  };
};
