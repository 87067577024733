import { BABD_CONTRACT_ADDRESS, BAB_CONTRACT_ADDRESS } from './viem';

export const CONTRACT_URLS = {
  BAB_CONTRACT_URL: `https://bscscan.com/token/${BAB_CONTRACT_ADDRESS}`,
  BAB_INFO_URL: 'https://www.binance.com/en/BABT',
  BABD_CONTRACT_URL: `https://bscscan.com/token/${BABD_CONTRACT_ADDRESS}`,
  BABD_UNISWAP_URL: `https://app.uniswap.org/swap?outputCurrency=${BABD_CONTRACT_ADDRESS}&chain=bsc`,
};

export const THEME = {
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
};
