import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Address, ContractFunctionExecutionError } from 'viem';
import { publicClient } from '../config/viem';
import { BABD_ABI, BABD_CONTRACT_ADDRESS } from '../config/viem';
import { useWalletClient } from 'wagmi';
import { useChainSwitchSelf } from './useChainSwitch';
import { useTranslation } from 'react-i18next';

export const useParticipate = (onSuccess: () => void) => {
  const [isParticipating, setIsParticipating] = useState(false);
  const { data: walletClient } = useWalletClient();
  const { ensureBscChain } = useChainSwitchSelf();
  const toast = useToast();
  const { t } = useTranslation();

  const participate = async () => {
    if (!walletClient) {
      toast({ title: t('error'), description: t('wallet.connect'), status: 'error', duration: 3000, isClosable: true });
      return;
    }

    try {
      setIsParticipating(true);

      // 确保在BSC链上
      const isBscChain = await ensureBscChain();
      if (!isBscChain) {
        return;
      }

      const { request } = await publicClient.simulateContract({
        address: BABD_CONTRACT_ADDRESS as Address,
        abi: BABD_ABI,
        functionName: 'participate',
        account: walletClient.account,
      });

      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });

      toast({ title: t('success'), description: 'Successfully participated in the current round', status: 'success', duration: 3000, isClosable: true });

      onSuccess();
    } catch (error) {
      console.error('Failed to participate:', error);

      let errorMessage = String(error).substring(0, 200);
      if (error instanceof ContractFunctionExecutionError) {
        // 处理常见的合约错误
        if (error.message.includes('Must hold Binance Account Bound Token')) {
          errorMessage = 'You must hold a Binance Account Bound Token to participate';
        }
      }

      toast({ title: t('error'), description: errorMessage, status: 'error', duration: 5000, isClosable: true });
    } finally {
      setIsParticipating(false);
    }
  };

  return {
    isParticipating,
    participate,
  };
};
