import { useToast } from '@chakra-ui/react';
import { useAccount, useChainId, useConnect, useSwitchChain } from 'wagmi';
import { bsc } from 'viem/chains';
import { useTranslation } from 'react-i18next';

export const useChainSwitchSelf = () => {
  const chainId = useChainId();
  const chain = useSwitchChain();
  const toast = useToast();
  const { t } = useTranslation();

  const ensureBscChain = async (): Promise<boolean> => {
    if (chainId !== bsc.id) {
      try {
        await chain.switchChainAsync({ chainId: bsc.id });
        return true;
      } catch (error) {
        console.error('Failed to switch network:', error);
        toast({ title: t('error'), description: 'Failed to switch to BSC ' + String(error), status: 'error', duration: 3000, isClosable: true });
        return false;
      }
    }

    return true;
  };

  return {
    ensureBscChain,
  };
};
