import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  HStack,
  Link,
  VStack,
  Divider,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ExternalLinkIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { CONTRACT_URLS } from '../config/constants';

interface Language {
  code: string;
  name: string;
  flag: string;
}

interface HeaderProps {
  languages: Language[];
  currentLanguage: string;
  onChangeLanguage: (langCode: string) => void;
}

export const Header: React.FC<HeaderProps> = ({ languages, currentLanguage, onChangeLanguage }) => {
  const { t } = useTranslation();

  const currentLang = languages.find((lang) => lang.code === currentLanguage) || languages[0];

  return (
    <Card>
      <Alert status="error" variant="solid" mb={4}>
        <AlertIcon />
        <VStack align="start" spacing={1}>
          <AlertTitle fontSize="lg">⚠️ {t('notice.title')}</AlertTitle>
          <AlertDescription>{t('notice.content')}</AlertDescription>
        </VStack>
      </Alert>
      <CardHeader pb={0}>
        <VStack spacing={2}>
          <HStack justifyContent="space-between" width="100%">
            <Heading as="h1" size="xl" textAlign="center" color="blue.600">
              BAB Dividend
            </Heading>
            <Menu>
              <MenuButton as={Button} size="sm" colorScheme="blue" variant="outline" rightIcon={<ChevronDownIcon />}>
                <HStack>
                  <Text>{currentLang.flag}</Text>
                  <Text>{currentLang.name}</Text>
                </HStack>
              </MenuButton>
              <MenuList>
                {languages.map((lang) => (
                  <MenuItem key={lang.code} onClick={() => onChangeLanguage(lang.code)} backgroundColor={currentLang.code === lang.code ? 'blue.50' : undefined}>
                    <HStack>
                      <Text>{lang.flag}</Text>
                      <Text>{lang.name}</Text>
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
          <Divider />
        </VStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={6}>
          <Text fontSize="lg" color="gray.600" textAlign="center" maxW="3xl" mx="auto" lineHeight="tall">
            {t('project.intro')}
          </Text>

          <VStack spacing={4} align="start" w="100%" px={4}>
            <Text fontWeight="semibold">{t('project.features')}</Text>
            <UnorderedList spacing={2}>
              <ListItem>{t('project.liquidity')}</ListItem>
              <ListItem>{t('project.distribution')}</ListItem>
              <ListItem>{t('project.dividend')}</ListItem>
              <ListItem>{t('project.reward')}</ListItem>
              <ListItem>{t('project.rules')}</ListItem>
              <ListItem>{t('project.unclaimed')}</ListItem>
              <ListItem>{t('project.contribution')}</ListItem>
            </UnorderedList>
          </VStack>

          <Text fontSize="md" color="gray.600" textAlign="center" maxW="3xl" mx="auto">
            {t('project.vision')}
          </Text>

          <VStack spacing={4} align="center" w="100%">
            <Button as={Link} href={CONTRACT_URLS.BABD_UNISWAP_URL} isExternal colorScheme="blue" size="lg" rightIcon={<ExternalLinkIcon />}>
              {t('buyOnUniswap')}
            </Button>

            <HStack justify="center" spacing={8} pt={2} wrap="wrap" shouldWrapChildren>
              <Link href={CONTRACT_URLS.BABD_CONTRACT_URL} isExternal color="blue.500" fontWeight="medium">
                BABD {t('contract')} <ExternalLinkIcon mx="2px" />
              </Link>
              <Link href={CONTRACT_URLS.BAB_CONTRACT_URL} isExternal color="blue.500" fontWeight="medium">
                BAB {t('contract')} <ExternalLinkIcon mx="2px" />
              </Link>
              <Link href={CONTRACT_URLS.BAB_INFO_URL} isExternal color="blue.500" fontWeight="medium">
                {t('about')} BAB <ExternalLinkIcon mx="2px" />
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
};
