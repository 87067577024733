import React from 'react';
import { VStack, HStack, Button, ButtonGroup, Tag, Card, CardBody, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Address } from 'viem';
import { ErrorAlert } from './ErrorAlert';
import { formatAddress } from '../utils/format';

interface UserActionsProps {
  account: Address | undefined;
  error: string;
  hasBAB: boolean;
  isLoading: boolean;
  isParticipating: boolean;
  canClaim: boolean;
  hasParticipatedInCurrentRound: boolean;
  roundDataLoading: boolean;
  onConnect: () => void;
  onParticipate: () => void;
}

export const UserActions: React.FC<UserActionsProps> = ({
  account,
  error,
  hasBAB,
  isLoading,
  isParticipating,
  canClaim,
  hasParticipatedInCurrentRound,
  roundDataLoading,
  onConnect,
  onParticipate,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {!account ? (
          <VStack spacing={4}>
            {error && <ErrorAlert error={error} />}
            <Button colorScheme="blue" onClick={onConnect} isLoading={isLoading} loadingText={t('wallet.connecting')} width="100%">
              {t('wallet.connect')}
            </Button>
          </VStack>
        ) : (
          <VStack spacing={4} align="center">
            <HStack spacing={4} wrap="wrap" justify="center">
              <Tooltip label={account} placement="top">
                <Tag size="lg" colorScheme="blue" cursor="pointer">
                  {formatAddress(account)}
                </Tag>
              </Tooltip>
              <Tag colorScheme={hasBAB ? 'green' : 'red'} size="lg">
                {hasBAB ? t('status.babHolder') : t('status.noBAB')}
              </Tag>
            </HStack>
            <ButtonGroup width="100%" justifyContent="center" spacing={4}>
              {canClaim ? (
                <Button colorScheme="blue" onClick={onParticipate} isLoading={isParticipating} loadingText={t('action.claiming')} isDisabled={!hasBAB || roundDataLoading}>
                  {t('action.claimAndParticipate')}
                </Button>
              ) : (
                <Button
                  colorScheme={hasParticipatedInCurrentRound ? 'gray' : 'green'}
                  onClick={onParticipate}
                  isLoading={isParticipating}
                  loadingText={t('action.participating')}
                  isDisabled={!hasBAB || hasParticipatedInCurrentRound || roundDataLoading}
                >
                  {hasParticipatedInCurrentRound ? t('action.alreadyParticipated') : t('action.participate')}
                </Button>
              )}
            </ButtonGroup>
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};
