import { http, createConfig } from 'wagmi';
import { bsc } from 'wagmi/chains';

// 创建wagmi配置
export const config = createConfig({
  chains: [bsc],
  transports: {
    [bsc.id]: http(),
  },
});
