import React, { useEffect } from 'react';
import { Box, VStack, Container, Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Header } from './components/Header';
import { UserActions } from './components/UserActions';
import { RoundDataCard } from './components/RoundDataCard';
import { HistoricalRoundsCard } from './components/HistoricalRoundsCard';
import { useBABBalance } from './hooks/useBABBalance';
import { useRoundData } from './hooks/useRoundData';
import { useHistoricalRounds } from './hooks/useHistoricalRounds';
import { useWallet } from './hooks/useWallet';
import { useParticipate } from './hooks/useParticipate';

const languages = [
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'zh', name: '中文', flag: '🇨🇳' },
  { code: 'ja', name: '日本語', flag: '🇯🇵' },
  { code: 'ko', name: '한국어', flag: '🇰🇷' },
  { code: 'ru', name: 'Русский', flag: '🇷🇺' },
];

function App() {
  const { i18n } = useTranslation();
  const { address, error, isLoading: isConnecting, connectWallet } = useWallet();
  const { hasBAB, checkBABBalance } = useBABBalance();
  const { isLoading: roundDataLoading, currentRound, nextRoundTime, lastParticipation, hasParticipatedInCurrentRound, loadRoundData } = useRoundData();
  const { isLoading: historicalLoading, historicalRounds, loadHistoricalRounds } = useHistoricalRounds();
  const { isParticipating, participate } = useParticipate(loadRoundData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    loadRoundData(address);
  }, [address]);

  useEffect(() => {
    if (nextRoundTime > 0) {
      loadHistoricalRounds(nextRoundTime);
    }
  }, [nextRoundTime, loadHistoricalRounds]);

  useEffect(() => {
    if (address) {
      checkBABBalance();
    }
  }, [address, checkBABBalance]);

  const canClaim = Boolean(address && lastParticipation > 0 && currentRound && lastParticipation === currentRound.timestamp - 172800);

  const changeLanguage = (langCode: string) => {
    i18n.changeLanguage(langCode);
  };

  return (
    <Box minH="100vh" py={8} bg="gray.50">
      <Container maxW="container.lg">
        <VStack spacing={8} align="stretch">
          <Header languages={languages} currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />

          <UserActions
            account={address}
            error={error}
            hasBAB={hasBAB}
            isLoading={isConnecting}
            isParticipating={isParticipating}
            canClaim={canClaim}
            hasParticipatedInCurrentRound={hasParticipatedInCurrentRound}
            roundDataLoading={roundDataLoading}
            onConnect={connectWallet}
            onParticipate={participate}
          />

          <RoundDataCard isLoading={roundDataLoading} currentRound={currentRound || { timestamp: 0, rewardPerParticipant: '0', userCount: 0, claimedCount: 0 }} />

          <Divider />

          <Box py={4}>
            <HistoricalRoundsCard isLoading={historicalLoading} historicalRounds={historicalRounds} />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}

export default App;
