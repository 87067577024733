import { createPublicClient, http, createWalletClient, custom } from 'viem';
import { bsc } from 'viem/chains';

// 合约地址 (使用checksum格式)
export const BABD_CONTRACT_ADDRESS = '0xE124cCA93d0070727fe9B014611CcA89BD7F2CB4';
export const BAB_CONTRACT_ADDRESS = '0x2B09d47D550061f995A3b5C6F0Fd58005215D7c8';

// 合约ABI
export const BABD_ABI = [
  // 读取函数
  {
    inputs: [],
    name: 'nextRoundTime',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ type: 'uint256' }],
    name: 'rounds',
    outputs: [
      { name: 'rewardPerParticipant', type: 'uint256' },
      { name: 'userCount', type: 'uint256' },
      { name: 'claimedCount', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ type: 'address' }],
    name: 'userLastParticipationTime',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ROUND_PERIOD',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  // 写入函数
  {
    inputs: [],
    name: 'participate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  // 事件
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: true, name: 'roundTime', type: 'uint256' },
    ],
    name: 'Participated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' },
      { indexed: true, name: 'roundTime', type: 'uint256' },
    ],
    name: 'RewardsDistributed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: 'newRoundTime', type: 'uint256' },
      { indexed: false, name: 'newRewardPool', type: 'uint256' },
    ],
    name: 'RoundTimeUpdated',
    type: 'event',
  },
] as const;

export const BAB_ABI = [
  {
    inputs: [{ type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

// 创建公共客户端
export const publicClient = createPublicClient({
  chain: bsc,
  transport: http(),
});

// 创建钱包客户端
export const getWalletClient = () => {
  if (!window.ethereum) throw new Error('No ethereum provider found');
  return createWalletClient({
    chain: bsc,
    transport: custom(window.ethereum),
  });
};
