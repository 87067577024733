import { useEffect, useState } from 'react';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { injected } from 'wagmi/connectors';
import { bscTestnet } from 'viem/chains';
import { useToast } from '@chakra-ui/react';

export const useWallet = () => {
  const { address, isConnected } = useAccount();
  const { connect, isPending: isLoading, error: connectError } = useConnect();
  const { disconnect, error: disconnectError } = useDisconnect();
  const [error, setError] = useState<string>('');
  const toast = useToast();

  // 更新错误状态
  useEffect(() => {
    if (connectError) {
      setError(connectError.message);
    } else if (disconnectError) {
      setError(disconnectError.message);
    } else {
      setError('');
    }
  }, [connectError, disconnectError]);

  // 连接钱包
  const connectWallet = async () => {
    try {
      await connect({
        connector: injected(),
        chainId: bscTestnet.id,
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to connect wallet';
      console.error('Failed to connect wallet:', error);
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // 断开连接
  const disconnectWallet = () => {
    try {
      disconnect();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to disconnect wallet';
      console.error('Failed to disconnect wallet:', error);
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // 检查网络并切换到BSC测试网
  useEffect(() => {
    if (isConnected && window.ethereum) {
      const switchToBscTestnet = async () => {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x61' }], // BSC Testnet chainId
          });
        } catch (switchError: any) {
          // 如果网络不存在，则添加网络
          if (switchError.code === 4902 || switchError.code === -32603) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x61',
                    chainName: 'BSC Testnet',
                    nativeCurrency: {
                      name: 'Binance Coin',
                      symbol: 'tBNB',
                      decimals: 18,
                    },
                    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                    blockExplorerUrls: ['https://testnet.bscscan.com'],
                  },
                ],
              });
            } catch (addError) {
              const errorMessage = addError instanceof Error ? addError.message : 'Failed to add BSC Testnet';
              console.error('Failed to add BSC Testnet:', addError);
              setError(errorMessage);
            }
          }
        }
      };

      switchToBscTestnet();
    }
  }, [isConnected]);

  return {
    address,
    isConnected,
    isLoading,
    error,
    connectWallet,
    disconnectWallet,
  };
};
