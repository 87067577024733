import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Common
      error: 'Error',
      success: 'Success',
      loading: 'Loading...',
      noData: 'No Data',
      time: 'Time',
      contract: 'Contract',
      about: 'About',
      buyOnUniswap: 'Buy BABD on Uniswap',

      // Notice
      notice: {
        title: 'Important Notice',
        content:
          "Please Note: This project's smart contract development, interface design, and contract auditing are all conducted by Artificial Intelligence (AI). While we strive to ensure the project's security and reliability, we strongly advise you to participate with caution and fully understand the associated risks.",
      },

      // Project Info
      project: {
        intro:
          'BABD (Binance Account Bound Dividend) is a dividend distribution project based on BAB (Binance Account Bound Token). This meme token features an initial 20% circulation in liquidity pools, with the remaining 80% allocated to BAB holders through a fair dividend system.',
        features: 'Key Features:',
        liquidity: 'Initial Liquidity Security: The initial 20% liquidity will be locked for 1 year within the first week of project launch, ensuring long-term stability and preventing rug pulls',
        distribution: 'Token Distribution: 20% for initial liquidity pool, 80% allocated to BAB holders through dividend system',
        dividend: 'Dividend Distribution: BAB holders can participate in dividend distribution every two days',
        reward: 'Fair Reward System: Each dividend is capped at 10,000 BABD per participant',
        rules: 'Transparent Rules: Distribution rules are permanently encoded in the smart contract and cannot be modified',
        unclaimed: 'Unclaimed Rewards: Any unclaimed BABD automatically rolls over to the next distribution period',
        contribution: 'Open Contribution: Anyone can contribute to the dividend pool by transferring BABD to the contract address',
        vision:
          "Ultimate Vision: To have BABD listed on Binance's centralized exchange, where trading fees generated from BABD transactions would be automatically channeled into the dividend pool. This would create a sustainable reward ecosystem where BAB holders continuously benefit from BABD's trading activity on Binance.",
      },

      // Round Data
      round: {
        current: 'Current Round',
        participants: 'Total Participants',
        claimPeriod: 'Claim Period',
        rewardPerson: 'Reward Per Person',
        rewardFormula: 'Reward Per Person = (Current Contract Balance - Previous Round Unclaimed Total) / Number of Participants',
        estimatedValue: '(Estimated value, actual reward will be calculated based on the number of participants)',
      },

      // Historical Data
      history: {
        title: 'Historical Rounds',
        loading: 'Loading historical data...',
        claimed: 'Claimed',
        noHistory: 'No historical data available',
      },

      // Wallet
      wallet: {
        connect: 'Connect Wallet',
        connected: 'Connected',
        disconnect: 'Disconnect',
        connecting: 'Connecting...',
        connectionFailed: 'Connection Failed',
        metamaskRequired: 'MetaMask is required to use this application.',
        installMetamask: 'Click here to install MetaMask',
      },

      // Actions
      action: {
        amount: 'Amount',
        enterAmount: 'Enter amount',
        participate: 'Participate',
        participating: 'Participating...',
        alreadyParticipated: 'Already Participated',
        claim: 'Claim',
        claiming: 'Claiming...',
        claimAndParticipate: 'Claim & Participate',
        approve: 'Approve',
      },

      // Status
      status: {
        babHolder: 'BAB Holder',
        noBAB: 'No BAB Token',
      },
    },
  },
  zh: {
    translation: {
      // Common
      error: '错误',
      success: '成功',
      loading: '加载中...',
      noData: '暂无数据',
      time: '时间',
      contract: '合约',
      about: '关于',
      buyOnUniswap: '在Uniswap购买BABD',

      // Notice
      notice: {
        title: '重要提示',
        content: '请注意：本项目的智能合约开发、界面设计和合约审计均由人工智能(AI)完成。虽然我们努力确保项目的安全性和可靠性，但我们强烈建议您谨慎参与，并充分了解相关风险。',
      },

      // Project Info
      project: {
        intro: 'BABD（币安账户绑定分红）是一个基于BAB（币安账户绑定通证）的分红发放项目。这个迷因代币特点是初始20%在流动性池中流通，剩余80%通过公平的分红系统分配给BAB持有者。',
        features: '主要特点：',
        liquidity: '初始流动性安全：项目启动后第一周内，初始20%流动性将锁定1年，确保长期稳定性并防止跑路',
        distribution: '代币分配：20%用于初始流动性池，80%通过分红系统分配给BAB持有者',
        dividend: '分红发放：BAB持有者每两天可以参与一次分红发放',
        reward: '公平奖励系统：每次分红每位参与者上限为10,000 BABD',
        rules: '透明规则：分配规则永久编码在智能合约中，无法修改',
        unclaimed: '未领取奖励：任何未领取的BABD自动结转到下一个分配期',
        contribution: '开放贡献：任何人都可以通过向合约地址转账BABD来贡献分红池',
        vision: '终极愿景：让BABD在币安中心化交易所上市，将BABD交易产生的交易费自动注入分红池。这将创建一个可持续的奖励生态系统，BAB持有者可以持续从BABD在币安的交易活动中受益。',
      },

      // Round Data
      round: {
        current: '当前轮次',
        participants: '参与人数',
        claimPeriod: '领取期限',
        rewardPerson: '每人奖励',
        rewardFormula: '每人奖励 = (当前合约余额 - 上轮未领取总额) / 参与人数',
        estimatedValue: '(预估值，实际奖励将根据参与人数计算)',
      },

      // Historical Data
      history: {
        title: '历史轮次',
        loading: '加载历史数据...',
        claimed: '已领取',
        noHistory: '暂无历史数据',
      },

      // Wallet
      wallet: {
        connect: '连接钱包',
        connected: '已连接',
        disconnect: '断开连接',
        connecting: '连接中...',
        connectionFailed: '连接失败',
        metamaskRequired: '需要安装MetaMask才能使用此应用',
        installMetamask: '点击此处安装MetaMask',
      },

      // Actions
      action: {
        amount: '金额',
        enterAmount: '输入金额',
        participate: '参与',
        participating: '参与中...',
        alreadyParticipated: '已参与',
        claim: '领取',
        claiming: '领取中...',
        claimAndParticipate: '领取并参与',
        approve: '授权',
      },

      // Status
      status: {
        babHolder: 'BAB持有者',
        noBAB: '无BAB代币',
      },
    },
  },
  ja: {
    translation: {
      // Common
      error: 'エラー',
      success: '成功',
      loading: '読み込み中...',
      noData: 'データなし',
      time: '時間',
      contract: 'コントラクト',
      about: '概要',
      buyOnUniswap: 'UniswapでBABDを購入',

      // Notice
      notice: {
        title: '重要なお知らせ',
        content:
          '注意：このプロジェクトのスマートコントラクト開発、インターフェース設計、およびコントラクト監査は、すべて人工知能（AI）によって実施されています。プロジェクトのセキュリティと信頼性を確保するよう努めていますが、慎重に参加し、関連するリスクを十分に理解することを強くお勧めします。',
      },

      // Project Info
      project: {
        intro:
          'BABD（Binanceアカウントバウンド配当）は、BAB（Binanceアカウントバウンドトークン）に基づく配当分配プロジェクトです。このミームトークンは、初期流動性プールに20%を流通させ、残りの80%を公平な配当システムを通じてBAB保有者に分配します。',
        features: '主な特徴：',
        liquidity: '初期流動性の安全性：プロジェクト開始後1週間以内に、初期流動性の20%を1年間ロックし、長期的な安定性を確保し、ラグプルを防止します',
        distribution: 'トークン分配：20%を初期流動性プール用、80%を配当システムを通じてBAB保有者に分配',
        dividend: '配当分配：BAB保有者は2日ごとに配当分配に参加可能',
        reward: '公平な報酬システム：各配当は参加者1人あたり10,000 BABDが上限',
        rules: '透明なルール：分配ルールはスマートコントラクトに永久的に組み込まれており、変更不可',
        unclaimed: '未請求の報酬：未請求のBABDは自動的に次の分配期間に繰り越し',
        contribution: 'オープンな貢献：誰でもコントラクトアドレスにBABDを送金することで配当プールに貢献可能',
        vision:
          '最終ビジョン：BABDをBinance中央取引所に上場させ、BABD取引から生じる取引手数料を自動的に配当プールに組み入れます。これにより、BAB保有者がBinanceでのBABD取引活動から継続的に利益を得られる持続可能な報酬エコシステムを創出します。',
      },

      // Round Data
      round: {
        current: '現在のラウンド',
        participants: '総参加者数',
        claimPeriod: '請求期間',
        rewardPerson: '1人あたりの報酬',
        rewardFormula: '1人あたりの報酬 = (現在のコントラクト残高 - 前回の未請求総額) / 参加者数',
        estimatedValue: '(推定値、実際の報酬は参加者数に基づいて計算されます)',
      },

      // Historical Data
      history: {
        title: '過去のラウンド',
        loading: '履歴データを読み込み中...',
        claimed: '請求済み',
        noHistory: '履歴データがありません',
      },

      // Wallet
      wallet: {
        connect: 'ウォレット接続',
        connected: '接続済み',
        disconnect: '切断',
        connecting: '接続中...',
        connectionFailed: '接続失敗',
        metamaskRequired: 'このアプリケーションを使用するにはMetaMaskが必要です。',
        installMetamask: 'MetaMaskをインストールするにはここをクリック',
      },

      // Actions
      action: {
        amount: '金額',
        enterAmount: '金額を入力',
        participate: '参加',
        participating: '参加中...',
        alreadyParticipated: '参加済み',
        claim: '請求',
        claiming: '請求中...',
        claimAndParticipate: '請求して参加',
        approve: '承認',
      },

      // Status
      status: {
        babHolder: 'BAB保有者',
        noBAB: 'BABなし',
      },
    },
  },
  ko: {
    translation: {
      // Common
      error: '오류',
      success: '성공',
      loading: '로딩 중...',
      noData: '데이터 없음',
      time: '시간',
      contract: '컨트랙트',
      about: '소개',
      buyOnUniswap: 'Uniswap에서 BABD 구매',

      // Notice
      notice: {
        title: '중요 공지',
        content:
          '주의: 이 프로젝트의 스마트 컨트랙트 개발, 인터페이스 설계 및 컨트랙트 감사는 모두 인공지능(AI)에 의해 수행되었습니다. 프로젝트의 보안과 신뢰성을 보장하기 위해 노력하고 있지만, 신중하게 참여하고 관련 위험을 충분히 이해할 것을 강력히 권장합니다.',
      },

      // Project Info
      project: {
        intro:
          'BABD(바이낸스 계정 바운드 배당)는 BAB(바이낸스 계정 바운드 토큰)를 기반으로 하는 배당 분배 프로젝트입니다. 이 밈 토큰은 초기 유동성 풀에 20%를 유통시키고, 나머지 80%를 공정한 배당 시스템을 통해 BAB 보유자에게 분배합니다.',
        features: '주요 특징:',
        liquidity: '초기 유동성 보안: 프로젝트 시작 후 1주일 이내에 초기 유동성 20%를 1년간 잠금하여 장기 안정성을 보장하고 러그풀 방지',
        distribution: '토큰 분배: 20%는 초기 유동성 풀용, 80%는 배당 시스템을 통해 BAB 보유자에게 분배',
        dividend: '배당 분배: BAB 보유자는 2일마다 배당 분배에 참여 가능',
        reward: '공정한 보상 시스템: 각 배당은 참가자당 10,000 BABD로 제한',
        rules: '투명한 규칙: 분배 규칙은 스마트 컨트랙트에 영구적으로 인코딩되어 수정 불가',
        unclaimed: '미청구 보상: 미청구된 BABD는 자동으로 다음 분배 기간으로 이월',
        contribution: '개방형 기여: 누구나 컨트랙트 주소로 BABD를 전송하여 배당 풀에 기여 가능',
        vision:
          '최종 비전: BABD를 바이낸스 중앙화 거래소에 상장하여 BABD 거래에서 발생하는 거래 수수료를 자동으로 배당 풀에 편입합니다. 이를 통해 BAB 보유자가 바이낸스에서의 BABD 거래 활동으로부터 지속적으로 혜택을 받는 지속 가능한 보상 생태계를 조성합니다.',
      },

      // Round Data
      round: {
        current: '현재 라운드',
        participants: '총 참가자 수',
        claimPeriod: '청구 기간',
        rewardPerson: '1인당 보상',
        rewardFormula: '1인당 보상 = (현재 컨트랙트 잔액 - 이전 라운드 미청구 총액) / 참가자 수',
        estimatedValue: '(추정치, 실제 보상은 참가자 수에 따라 계산됩니다)',
      },

      // Historical Data
      history: {
        title: '과거 라운드',
        loading: '기록 데이터 로딩 중...',
        claimed: '청구됨',
        noHistory: '기록 데이터 없음',
      },

      // Wallet
      wallet: {
        connect: '지갑 연결',
        connected: '연결됨',
        disconnect: '연결 해제',
        connecting: '연결 중...',
        connectionFailed: '연결 실패',
        metamaskRequired: '이 애플리케이션을 사용하려면 MetaMask가 필요합니다.',
        installMetamask: 'MetaMask 설치하기',
      },

      // Actions
      action: {
        amount: '금액',
        enterAmount: '금액 입력',
        participate: '참여',
        participating: '참여 중...',
        alreadyParticipated: '이미 참여함',
        claim: '청구',
        claiming: '청구 중...',
        claimAndParticipate: '청구 및 참여',
        approve: '승인',
      },

      // Status
      status: {
        babHolder: 'BAB 보유자',
        noBAB: 'BAB 없음',
      },
    },
  },
  ru: {
    translation: {
      // Common
      error: 'Ошибка',
      success: 'Успех',
      loading: 'Загрузка...',
      noData: 'Нет данных',
      time: 'Время',
      contract: 'Контракт',
      about: 'О проекте',
      buyOnUniswap: 'Купить BABD на Uniswap',

      // Notice
      notice: {
        title: 'Важное уведомление',
        content:
          'Обратите внимание: разработка смарт-контракта, дизайн интерфейса и аудит контракта этого проекта полностью выполнены искусственным интеллектом (ИИ). Хотя мы стремимся обеспечить безопасность и надежность проекта, мы настоятельно рекомендуем участвовать с осторожностью и полностью понимать связанные риски.',
      },

      // Project Info
      project: {
        intro:
          'BABD (Binance Account Bound Dividend) - это проект распределения дивидендов на основе BAB (Binance Account Bound Token). Этот мем-токен характеризуется начальной циркуляцией 20% в пулах ликвидности, а остальные 80% распределяются между держателями BAB через справедливую систему дивидендов.',
        features: 'Ключевые особенности:',
        liquidity:
          'Безопасность начальной ликвидности: начальная ликвидность 20% будет заблокирована на 1 год в течение первой недели запуска проекта, обеспечивая долгосрочную стабильность и предотвращая раг пулы',
        distribution: 'Распределение токенов: 20% для начального пула ликвидности, 80% распределяется между держателями BAB через систему дивидендов',
        dividend: 'Распределение дивидендов: держатели BAB могут участвовать в распределении дивидендов каждые два дня',
        reward: 'Справедливая система вознаграждений: каждый дивиденд ограничен 10,000 BABD на участника',
        rules: 'Прозрачные правила: правила распределения постоянно закодированы в смарт-контракте и не могут быть изменены',
        unclaimed: 'Невостребованные награды: любые невостребованные BABD автоматически переносятся на следующий период распределения',
        contribution: 'Открытый вклад: любой может внести вклад в пул дивидендов, переведя BABD на адрес контракта',
        vision:
          'Конечное видение: листинг BABD на централизованной бирже Binance, где комиссии за торговлю, генерируемые транзакциями BABD, будут автоматически направляться в пул дивидендов. Это создаст устойчивую экосистему вознаграждений, где держатели BAB постоянно получают выгоду от торговой активности BABD на Binance.',
      },

      // Round Data
      round: {
        current: 'Текущий раунд',
        participants: 'Всего участников',
        claimPeriod: 'Период требования',
        rewardPerson: 'Награда на человека',
        rewardFormula: 'Награда на человека = (Текущий баланс контракта - Общая сумма невостребованных с предыдущего раунда) / Количество участников',
        estimatedValue: '(Ориентировочное значение, фактическая награда будет рассчитана на основе количества участников)',
      },

      // Historical Data
      history: {
        title: 'Исторические раунды',
        loading: 'Загрузка исторических данных...',
        claimed: 'Востребовано',
        noHistory: 'Нет исторических данных',
      },

      // Wallet
      wallet: {
        connect: 'Подключить кошелек',
        connected: 'Подключено',
        disconnect: 'Отключить',
        connecting: 'Подключение...',
        connectionFailed: 'Ошибка подключения',
        metamaskRequired: 'Для использования этого приложения требуется MetaMask.',
        installMetamask: 'Нажмите здесь, чтобы установить MetaMask',
      },

      // Actions
      action: {
        amount: 'Сумма',
        enterAmount: 'Введите сумму',
        participate: 'Участвовать',
        participating: 'Участие...',
        alreadyParticipated: 'Уже участвует',
        claim: 'Получить',
        claiming: 'Получение...',
        claimAndParticipate: 'Получить и участвовать',
        approve: 'Подтвердить',
      },

      // Status
      status: {
        babHolder: 'Держатель BAB',
        noBAB: 'Нет BAB',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: (() => {
    const lang = navigator.language.toLowerCase();
    if (lang.startsWith('zh')) return 'zh';
    if (lang.startsWith('ja')) return 'ja';
    if (lang.startsWith('ko')) return 'ko';
    if (lang.startsWith('ru')) return 'ru';
    return 'en';
  })(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
