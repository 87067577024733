import { formatEther as viemFormatEther, Address } from 'viem';

export const formatDate = (timestamp: number): string => {
  return new Date(timestamp * 1000).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatEther = (wei: bigint): string => {
  return parseFloat(viemFormatEther(wei)).toFixed(4);
};

export const getMetaMaskInstallLink = () => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('chrome') > -1) {
      return 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
    } else if (userAgent.indexOf('firefox') > -1) {
      return 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/';
    }
  }
  return 'https://metamask.io/download/';
};

export const formatAddress = (address: Address): string => {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};
