import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Link, VStack, Box, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { getMetaMaskInstallLink } from '../utils/format';

interface ErrorAlertProps {
  error: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  const { t } = useTranslation();
  const isMetaMaskError = error.includes('MetaMask');

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      borderRadius="xl"
      p={6}
      bg="red.50"
      borderWidth="1px"
      borderColor="red.200"
    >
      <VStack spacing={4}>
        <AlertIcon boxSize="8" />
        <Box>
          <AlertTitle fontSize="lg" mb={2} color="red.600">
            {t('wallet.connectionFailed')}
          </AlertTitle>
          <AlertDescription maxWidth="sm" color="red.500">
            {isMetaMaskError ? (
              <VStack spacing={3}>
                <Text>{t('wallet.metamaskRequired')}</Text>
                <Link href={getMetaMaskInstallLink()} isExternal color="blue.500" fontWeight="medium" _hover={{ textDecoration: 'none', color: 'blue.600' }} display="inline-flex" alignItems="center">
                  {t('wallet.installMetamask')} <ExternalLinkIcon ml={1} />
                </Link>
              </VStack>
            ) : (
              error
            )}
          </AlertDescription>
        </Box>
      </VStack>
    </Alert>
  );
};
