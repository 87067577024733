import React from 'react';
import { Card, CardBody, VStack, Heading, Text, Spinner, Grid, GridItem, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RoundData } from '../types';
import { format } from 'date-fns';

interface RoundDataCardProps {
  isLoading: boolean;
  currentRound: RoundData;
}

export const RoundDataCard: React.FC<RoundDataCardProps> = ({ isLoading, currentRound }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <VStack spacing={4}>
            <Spinner />
          </VStack>
        </CardBody>
      </Card>
    );
  }

  // Calculate next round end time
  const nextRoundEndTime = currentRound.timestamp + 24 * 60 * 60 * 2; // Add 24 hours

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} align="stretch">
          <Heading size="md">{t('round.current')}</Heading>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem>
              <Text fontWeight="bold">{t('time')}</Text>
              <Text>{format(currentRound.timestamp * 1000, 'yyyy-MM-dd HH:mm')}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">{t('round.participants')}</Text>
              <Text>{currentRound.userCount}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">{t('round.claimPeriod')}</Text>
              <Text>
                {format(currentRound.timestamp * 1000, 'MM-dd HH:mm')} ~ {format(nextRoundEndTime * 1000, 'MM-dd HH:mm')}
              </Text>
            </GridItem>
            <GridItem>
              <Tooltip label={t('round.rewardFormula')} placement="top">
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold">{t('round.rewardPerson')}</Text>
                  <Text>{currentRound.rewardPerParticipant} BABD</Text>
                  {currentRound.userCount === 0 && (
                    <Text fontSize="sm" color="gray.500">
                      {t('round.estimatedValue')}
                    </Text>
                  )}
                </VStack>
              </Tooltip>
            </GridItem>
          </Grid>
        </VStack>
      </CardBody>
    </Card>
  );
};
