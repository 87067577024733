import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { WagmiConfig } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from './config/wagmi';
import App from './App';
import './i18n/config';

const queryClient = new QueryClient();

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.50',
        minHeight: '100vh',
      },
    },
  },
  components: {
    Card: {
      baseStyle: {
        container: {
          boxShadow: 'lg',
          rounded: 'lg',
          bg: 'white',
        },
      },
    },
    Button: {
      defaultProps: {
        size: 'lg',
      },
    },
  },
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </QueryClientProvider>
    </WagmiConfig>
  </React.StrictMode>,
);
